
  import { Debounce } from '@/utils/decorators'
  import { fixPrice, utcToLocal } from '@/utils/general'
  import dayjs from 'dayjs'
  import Vue from 'vue'
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'

@Component({
  methods: {
    dayjs,
    fixPrice,
    utcToLocal,
  },
})
  export default class SalesReport extends Vue {
  @Prop() reports
  @Prop({ default: undefined, type: Number }) idExecutive
  executives = []
  reportsAttribute = []
  utcOffset = dayjs().utcOffset()

  filterByExecutive (executive, id?) {
    if (id) {
      const filterById = this.reportsAttribute.filter(report => report.executive === id)
      executive = filterById?.[0]?.executiveName
    }
    return this.reportsAttribute.filter(report =>
      report.executiveName === executive
    )
  }

  getData () {
    if (!this.reports) return
    const reports = this.reports.getSalesFinancialReport
    if (!reports) return
    this.reportsAttribute = reports

    const executives = []
    reports.forEach(report => {
      if (!executives.includes(report.executiveName)) {
        executives.push(report.executiveName)
      }
    })
    this.executives = executives
  }

  get salesByAcquisitionType () {
    const { reportsAttribute } = this

    return {
      purchase: reportsAttribute.filter(sale => sale.acquisitionType.name === 'purchase'),
      consignment: reportsAttribute.filter(sale => sale.acquisitionType.name === 'consignment'),
    }
  }

  get totalCost () {
    const { salesByAcquisitionType, reportsAttribute } = this

    return {
      purchase: salesByAcquisitionType.purchase.reduce((sum, sale) => sum += Number(sale.totalCost || 0), 0),
      consignment: salesByAcquisitionType.consignment.reduce((sum, sale) => sum += Number(sale.totalCost || 0), 0),
      total: reportsAttribute.reduce((sum, sale) => sum += Number(sale.totalCost || 0), 0),
    }
  }

  get listPrice () {
    const { salesByAcquisitionType, reportsAttribute } = this

    return {
      purchase: salesByAcquisitionType.purchase.reduce((sum, sale) => sum += Number(sale.listPrice || 0), 0),
      consignment: salesByAcquisitionType.consignment.reduce((sum, sale) => sum += Number(sale.listPrice || 0), 0),
      total: reportsAttribute.reduce((sum, sale) => sum += Number(sale.listPrice || 0), 0),
    }
  }

  get projectedUtility () {
    const { totalCost, listPrice } = this

    return {
      purchase: listPrice.purchase - totalCost.purchase,
      consignment: listPrice.consignment - totalCost.consignment,
      total: listPrice.total - totalCost.total,
    }
  }

  get totalUtility () {
    let count = 0
    this.reportsAttribute.forEach(report => count += report.utility)
    return count
  }

  get totalStockCost () {
    let count = 0
    const reports = this.idExecutive ? this.reportsAttribute.filter(report => report.executive === this.idExecutive) : this.reportsAttribute
    reports.forEach(report => {
      count += Number(report.totalCost)
    })
    return count
  }

  get totalSalePrice () {
    let count = 0
    const reports = this.idExecutive ? this.reportsAttribute.filter(report => report.executive === this.idExecutive) : this.reportsAttribute
    reports.forEach(report => {
      count += Number(report.agreedPrice)
    })
    return count
  }

  @Watch('reports', { immediate: true, deep: true })
  @Debounce(500)
  onReportsChange () {
    this.getData()
  }
  }

