
  import { deepCopy, fixPrice, utcToLocal } from '@/utils/general'
  import dayjs from 'dayjs'
  import Vue from 'vue'
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'

@Component({
  methods: {
    dayjs,
    fixPrice,
    utcToLocal,
  },
})
  export default class ReserveReport extends Vue {
  @Prop() reports
  @Prop({ default: undefined, type: Number }) idExecutive
  executives = []
  reportsAttribute = []
  utcOffset = dayjs().utcOffset()

  filterByExecutive (executive, id?) {
    if (id) {
      const filterById = this.reportsAttribute.filter(report => report.executive === id)
      executive = filterById?.[0]?.executiveName
    }
    return this.reportsAttribute.filter(report =>
      report.executiveName === executive
    )
  }

  getData () {
    if (!this.reports) return
    let reports = deepCopy(this.reports.getReserveFinancialReport)
    if (!reports) return
    reports = reports.sort((prev, next) =>
      dayjs(prev.deliveryDate).diff(dayjs(next.deliveryDate))
    )
    this.reportsAttribute = reports

    const executives = []
    reports.forEach(report => {
      if (!executives.includes(report.executiveName)) {
        executives.push(report.executiveName)
      }
    })
    this.executives = executives
  }

  getReportUtility (report) {
    return Number(report.listPrice || 0) - Number(report.totalCost || 0)
  }

  get stockCost () {
    return {
      purchase: this.ownStock.reduce((sum, reserve) => sum + (Number(reserve.totalCost) || 0), 0),
      consignment: this.consignmentStock.reduce((sum, reserve) => sum + (Number(reserve.totalCost) || 0), 0),
      total: this.reportsAttribute.reduce((sum, reserve) => sum + (Number(reserve.totalCost) || 0), 0),
    }
  }

  get salePrice () {
    return {
      purchase: this.ownStock.reduce((sum, reserve) => sum + (Number(reserve.listPrice) || 0), 0),
      consignment: this.consignmentStock.reduce((sum, reserve) => sum + (Number(reserve.listPrice) || 0), 0),
      total: this.reportsAttribute.reduce((sum, reserve) => sum + (Number(reserve.listPrice) || 0), 0),
    }
  }

  get projectedUtility () {
    return {
      purchase: this.salePrice.purchase - this.stockCost.purchase,
      consignment: this.salePrice.consignment - this.stockCost.consignment,
      total: this.salePrice.total - this.stockCost.total,
    }
  }

  get ownStock () {
    return this.reportsAttribute
      .filter(reserve => reserve.acquisitionType.name === 'purchase')
  }

  get consignmentStock () {
    return this.reportsAttribute
      .filter(reserve => reserve.acquisitionType.name === 'consignment')
  }

  @Watch('reports', { immediate: true, deep: true })
  onReportsChange () {
    this.getData()
  }
  }

