
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { fixAmountFormatted, fixPrice } from '@/utils/general'
  import { fixColor, lowOpacityColor } from '@/utils/dashboards'
  import { Legend } from '../Components/Legend'
  import { Criteria } from './Criteria'
  import { PeriodChart } from '../periodChart'

// Grafico de linea para un solo tipo de dato que se separa por un criterio dado
@Component
  export default class LineChartByCriteria extends PeriodChart {
  @Prop() prevMonthRecords
  @Prop() pathToDate: string[]
  @Prop() pathToValue: string[]
  @Prop() criterias: Criteria[]
  @Prop({ default: true, type: Boolean }) showPrevMonth
  @Prop({ default: undefined, type: Function }) dataTransformer
  @Prop({ default: 'line', type: String }) chartType
  @Prop({ default: true, type: Boolean }) sortLegends

  legends: Legend[] = []

  async getData () {
    const {
      criterias,
      limitRange,
      getObjectAttribute,
      pathToDate,
      pathToValue,
      isCurrency,
      monthly,
      months,
      showTotal,
    } = this

    const parsedRecords = {
      currentMonth: this.records?.records?.aggregate?.nodes || this.records?.records || this.records || [],
      prevMonth: this.prevMonthRecords?.records?.aggregate?.nodes || this.prevMonthRecords?.records || this.prevMonthRecords || [],
    }
    this.Debug('RECORDS', parsedRecords)

    if (this.dataTransformer) {
      parsedRecords.currentMonth = await this.dataTransformer(parsedRecords.currentMonth)
      parsedRecords.prevMonth = await this.dataTransformer(parsedRecords.prevMonth)
    }

    const data = criterias.map(criteria => {
      const dataByCategory = {
        currentMonth: parsedRecords.currentMonth.filter(criteria.filter),
        prevMonth: parsedRecords.prevMonth.filter(criteria.filter),
      }

      return {
        name: criteria.name,
        data: {
          currentMonth: limitRange.currentMonth.map(date => {
            const filterByDate = dataByCategory.currentMonth.filter(item => dayjs(getObjectAttribute(item, pathToDate)).isSame(dayjs(date), this.monthly ? 'month' : 'day'))
            if (pathToValue) {
              let sum = 0
              filterByDate.forEach(item => sum += getObjectAttribute(item, pathToValue))
              if (monthly) {
                return Math.round(sum / 30)
              }
              return sum
            } else {
              return filterByDate.length
            }
          }),
          prevMonth: limitRange.prevMonth.map(date => {
            const filterByDate = dataByCategory.prevMonth.filter(item => dayjs(getObjectAttribute(item, pathToDate)).isSame(dayjs(date), this.monthly ? 'month' : 'day'))
            if (pathToValue) {
              let sum = 0
              filterByDate.forEach(item => sum += getObjectAttribute(item, pathToValue))
              if (monthly) {
                return Math.round(sum / 30)
              }
              return sum
            } else {
              if (monthly) {
                return Math.round(filterByDate.length / 30)
              }
              return filterByDate.length
            }
          }),
        },
        color: fixColor(criteria.color),
        type: this.chartType,
      }
    })

    const currentMonthData = data.map(item => {
      const { name, color, type, data } = item
      const { currentMonth } = data
      return {
        name,
        color,
        type,
        data: this.nullZeroValues ? currentMonth.map(item => item === 0 ? null : item) : currentMonth,
      }
    })

    const prevMonthData = data.map(item => {
      const { name, color, type, data } = item
      const { prevMonth } = data
      return {
        name: name + ' (mes anterior)',
        color: lowOpacityColor(color),
        type,
        data: this.nullZeroValues ? prevMonth.map(item => item === 0 ? null : item) : prevMonth,
      }
    })

    this.legends = currentMonthData
      // .slice(0, this.excludeLastMonth ? currentMonthData.length - 2 : currentMonthData.length - 1)
      .map(item => {
        const { name, color, data } = item
        let value = 0
        data
          .slice(0, this.excludeLastMonth ? data.length - 1 : data.length)
          .forEach(item => value += item || 0)
        if (monthly) {
          value /= data.filter(item => item !== null).length - (this.excludeLastMonth ? 1 : 0)
        }
        value = Math.round(value)
        return {
          name,
          color,
          value: value || 0,
        }
      }).filter(item => this.filterZeroLegends ? item.value !== 0 : true)
    this.Debug(this.legends)

    this.series = [...currentMonthData, ...(this.showPrevMonth ? prevMonthData : [])]

    if (showTotal) {
      const totals = this.generateTotals(currentMonthData)

      this.series.push({
        name: 'Total',
        data: this.nullZeroValues ? totals.map(item => item === 0 ? null : item) : totals,
        color: lowOpacityColor('#737373'),
        type: 'area',
        stroke: {
          curve: 'smooth',
        },
      })

      if (!monthly) {
        const prevMonthTotals = this.generateTotals(prevMonthData)

        this.series.push({
          name: 'Total (mes anterior)',
          data: this.nullZeroValues ? prevMonthTotals.map(item => item === 0 ? null : item) : prevMonthTotals,
          color: '#737373',
          type: 'line',
          stroke: {
            curve: 'smooth',
          },
        })
      }
    }

    if (this.accumulated) {
      for (let i = 0; i < this.series.length; i++) {
        this.series[i].data = this.generateAccumulated(this.series[i].data)
      }
    }

    if (!monthly) {
      this.series.forEach(serie => {
        serie.data = this.fillWithNull(serie.data, this.currentMonthLength)
      })
    }

    this.chartOptions = {
      chart: {
        type: 'line',
        stacked: false,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        show: false,
      },
      stroke: {
        width: 2,
        curve: 'straight',
      },
      markers: {
        size: 3,
        strokeWidth: 0,
      },
      xaxis: {
        categories: monthly ? this.limitRange.currentMonth.map(date => dayjs(date)) : this.generateDayRange(this.currentMonth.start, this.currentMonth.end, monthly),
        type: 'category',
        labels: {
          formatter (val) {
            return monthly ? months[dayjs(val).format('MM')].short : dayjs(val).format('DD/MM')
          },
          style: {
            fontSize: '9px',
            colors: '#737373',
            fontFamily: 'Arial',
          },
        },
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter (val) {
            if (val >= 1000000) {
              return `${fixAmountFormatted(val / 1000000)}M`
            } else if (isCurrency) {
              return fixPrice(val)
            } else {
              return fixAmountFormatted(val)
            }
          },
          style: {
            fontSize: '9px',
            colors: '#737373',
            fontFamily: 'Arial',
          },
        },
      },
    }
  }

  generateTotals (values) {
    const totals = []
    values.forEach(item => {
      item.data.forEach((value, index) => {
        if (!totals[index]) {
          totals[index] = 0
        }
        totals[index] += value
      })
    })
    return totals
  }

  get watchData () {
    const { records, prevMonthRecords, dates } = this
    return {
      records,
      prevMonthRecords,
      dates,
    }
  }

  @Watch('watchData', { immediate: false, deep: true })
  async update () {
    this.processingData = true
    await this.getData()
    this.processingData = false
  }
  }
